@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

// Bootstrap Colors
$gray-base:                 #000 !default;
$gray-darker:               lighten($gray-base, 13.5%) !default;
$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighting:             lighten($gray-base, 75%) !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

// Colors
$light-gray:                #f0f5f8 !default;
$sky-blue:                  #c7e5f6 !default;
$water-blue:                #087dc2 !default;
$light-water-blue:          #0A9DF3 !default;
$aqua-blue:                 #80CEFE !default;
$dark-gray:                 #838383 !default;
$royal-red:                 #ec2227 !default;
$dark-red:                  #9B0D11 !default;
$light-red:                 #FDFDFD !default;
$pale-green:                #D6EFB6 !default;
$light-green:				#dff0d8 !default;
$green:						#3c763d !default;
$dark-water-blue:			#065888 !default;
$orange:			        #FF7000 !default;
$pacific-blue:			    #008FCB !default;
									
$light-green:				#dff0d8 !default;

$dark-blue:                 #3b5998 !default;
$dark-orange:               #dd4b39 !default;

$light-green:				#dff0d8 !default;

$dark:                      #000 !default;
$light:                     #fff !default;
$saffron:                   #F5D76E !default;
$dark-green:                #1d771d !default;

$lightyellow:				#c29a08 !default;
$light-lemon-yellow:   		#fcf8e3 !default;
$light-brown:	    		#8a6d3b !default;
$separator-color:           #959B9E !default;
$blue:            			#0000FF !default;
$dark-pink:					#b71f78 !default;
$darker-gra:				#908e8e !default;

$high-light-green:          #5cb85c !default;
$high-light-yellow:         #fabc06 !default;
$high-light-grey:           #57889c !default;

$brand-color-1:             #ff8c00;
$brand-color-2:             #825e44;
$brand-color-3:             #DECECE;
$brand-color-4:             #f0f5f8;
$brand-color-5:             #777;
$brand-color-6:             #E29A66;
$brand-color-7:             #E86609;
$brand-color-8:             $dark-blue;
$brand-color-9:             $dark-orange;
$brand-color-10:			$dark-water-blue;
$brand-color-11:			$lightyellow;
$brand-color-12:			$light-lemon-yellow;
$brand-color-13:			$light-brown;
$brand-color-14:			$blue;
$brand-color-15:			$pacific-blue;
$brand-color-16:			$dark-pink;
$brand-color-17:			$dark-green;


$brand-color-25:            #2EB51E;
$sf-submit-bg-color: 		$brand-color-25;
$brand-color-26:			#f9f0e0;
$water-blue: 				$brand-color-2;
$royal-red:					#ec2227 !default;
$brand-color-27:			$royal-red;

$royal-red:                 #ec2227 !default;
$brand-color-27:			$royal-red;


$brand-color-28:			#087dc2 !default;
$brand-color-29:            #eee6e6 !default; 
$brand-color-30:            $brand-color-2 !default; 
$brand-color-31:            #FFA500 !default;
$brand-color-32:            $gray; 
$brand-color-23:            $light;
$brand-color-24:            $dark;

$top-bar-bgcolor: 			$brand-color-30;
$brand-color-18:			$brand-color-30;
$brand-color-19:			$brand-color-31;
$brand-color-20:			$brand-color-17;
$brand-color-21:			$saffron;
$brand-color-22:            $gray-lighter;
$brand-color-33:            $gray-light;
$brand-color-34:            $gray-lighting;
$brand-color-23:            $light;
$brand-color-35:            $green;
$brand-color-36:            $dark-gray;
$brand-color-37: 			$royal-red;
$brand-color-38: 			#cacaca;

$available-status:			$green;
$unavailable-status:		$dark-orange;
$price-changed-status:		$orange;

$body-bg:                   #EEEFF3 !default;
$body-color:                $dark-gray !default;

$text-color:                $dark-gray !default;
$text-muted:                $light-gray !default;

$link-color:				$brand-color-1 !default;
$link-hover-color:			darken($link-color, 15%) !default;
$link-hover-decoration:		underline !default;

$font-size-root:            16px !default;

$font-size-base:            12px !default;

$font-size-h1:				2.25rem !default; // 36px
$font-size-h2:				1.5rem !default; // 24px
$font-size-h3:				1.125rem !default; // 18px
$font-size-h4:				0.875rem !default; // 14px
$font-size-h5:				0.75rem !default; // 12px
$font-size-h6:				0.625rem !default; // 10px
$font-size-h7:				0.6875rem !default; // 11px
$font-size-sign-small:		0.3750rem !default; //6px
$font-size-h7:				0.95rem !default;	//15px

$font-size-number:			1.375rem !default; // 22px
$font-size-number-small:	1.125rem !default; // 22px

$font-weight-base:			600 !default;

$font-family-sans-serif:    "Open Sans", Helvetica, Arial, sans-serif !default;
$font-family-base:          $font-family-sans-serif !default;
$font-family-number:        "Open Sans";
$font-family-autosuggest:   BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;

$line-height:               1.5 !default;

$enable-hover-media-query:  false !default;

$spacer:					1rem !default;
$spacer-x:					$spacer !default;
$spacer-y:					$spacer !default;
$border-width:				.0625rem !default;

$headings-margin-bottom:	($spacer * 0.5) !default;

$hr-border-color:			rgba(0,0,0,.1) !default;
$hr-border-width:			$border-width;

$grid-padding:				1.875rem !default; // 30px
$base-padding:				1.875rem !default; //30px
$base-margin:				1.875rem !default; //30px

$advertise-bgcolor:			$light !default;

$star-rating:				#F3C103;

$sm-screen-size:			1024px;
$md-screen-size:			1280px;
$lg-screen-size:			1600px;

$sm-width:					960px;
$md-width:					1200px;
$lg-width:					1400px;

$modal-sm-size:				900px !default;
$modal-md-size:				1000px !default;
$modal-lg-size:				1200px !default;

$modal-md-content-size:		550px; 

$confirmed-text-color:		green !default;
$waiting-text-color:		#FFBF00 !default;
$cancelled-text-color:		red !default;

$gray-darkest:				#303030 !default;

$ticketed-row-color:		lighten(green,70);//#e6ffe6;
$cancelled-row-color:		$brand-color-4;
$fa-font-path:				"../fonts/font-awesome" ;

$dark-shadow: 				0 0 15px rgba(0, 0, 0, 0.8);

//** recommended product icon conatiner 
$recommended-product-icon-container-color: 	#444 !default;
$recommended-product-icon-color: 			#dea310 !default;

//** hotel modal header container
$hotel-modal-header-container-bg-color: 	$light-water-blue;
$mandatory-asterisk-color: $royal-red;
$action-btn-hover: #9b0d11 !default;
