
$btn-default-color:           $light;

$input-bgcolor:                    $light !default;
$input-border-color:               lighten($brand-color-5, 30);

// Sorter Variables--------------------------------
$sorter-bgcolor:                   $light !default;
$sorter-textcolor:                 $brand-color-5 !default;
$sorter-list-bgcolor:              $brand-color-4 !default;
//-------------------------------------------
// Pax-info Variables--------------------------------
$asterisk-bgcolor:                  $brand-color-27 ;
$asterisk-fontsize:                 $font-size-sign-small;
//-------------------------------------------

// Full screen modal Variables
$full-screen-modal-header-height: 4rem; 
$highlights-modal-sidebar-height: 3rem;
$full-screen-modal-heading-height: 2.5rem;
