@mixin make-input_private {
	input[type="text"], input[type="number"],  select, textarea {
   		width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    border: 1px solid;
	    border-color: $input-border-color;
	}
 
	input[type="text"], input[type="number"], select {
		background-color: $input-bgcolor;
	    border: 1px solid;
	    border-color: $input-border-color;
	    height: 34px;
	    position: relative;
	    padding: 0.3rem 1rem;
	}
	
	input[type="number"] {
		font-family: $font-family-number;
	}
	input[type="text"].disabled-input{
		background-color: $gray-lightest;
		cursor: not-allowed;
	}
}


@mixin  make-input { 
	@include make-input_private; 
}