.multi-select{
	.multiselect-parent {
	 	 position: relative;
	   display: inline-block;
	   vertical-align: middle;
	      width: 100%;
	}	
	.open>.dropdown-menu {
		    display: block;
			height: auto !important;
	}
		
	.dropdown-menu {
	    position: absolute;
	    top: 100%;
	    left: 0;
	    z-index: 1000;
	    display: none;
        list-style: none;
        width: 100%;
	    padding: 5px 0;
	    list-style: none;
	    font-size: 14px;
	    text-align: left;
	    background-color: $light;
	    max-height: 250px;
	    border: 1px solid $gray-lighter;
	    .divider{
	        height: 0;
    		margin: 0;
	    }
	}		
			   
   	ul{
   		margin:0;
   	}
	.caret{
	    left: 95%;
    	top: 50%;
    	position: absolute;
	}
	   	
	.dropdown-header {
	    font-size: 12px;
	    color: $gray;
	}

	.dropdown-header, .dropdown-menu>li>a {
	    display: block;
	    padding: 3px 20px 7px 20px;
	    line-height: 1.42857;
	    white-space: nowrap;
	    font-weight: 400;
	    color: inherit;    
	    &:disabled{
	    	cursor:not-allowed;
	    }
	}
	  
	.multi-select-field {
		   width: 100%;
		   padding: 0.5rem 1rem;
		   background: none;
		   font-size: 0.875rem;
		   vertical-align: middle;
		   line-height: normal;
		   background-color: $input-bgcolor;
		   border: 1px solid;
		   border-color: $input-border-color;
		   max-height: 34px;
	       text-align: left;
	       @include text-overflow;
	}
	.dropdown-menu>li>a {
		outline: none;
    	cursor: pointer;	
	}	
}