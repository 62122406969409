@mixin dynamic-form_private{
	
	&__save-button{
		display: table;
		content:'';
		width:100%;
		padding: 1rem 1rem 1rem 4rem;
	    
		&_submit {
			float: right;
			font-size: 1.25rem;
			color: $light;
			background-color: $brand-color-2;
			text-transform: capitalize;
			width: 33%;
		    border: none;
	        margin-top: 1rem;
    		margin-bottom: 1rem;		
		}
	}	

	.dynamic-number-field, .dynamic-text-field, .dynamic-text-area-field, .dynamic-single-select, .dynamic-multi-select, .dynamic-date-field, .date-field , .dynamic-date-range-field, .dynamic-time-field, .dynamic-html-field, .dynamic-selection-grouped{
		padding-top: 0.25rem;
    	padding-bottom: 0.25rem;
   	    min-height: 90px;

   	    &__value{
    	    padding-right: 2.5rem;
    	    &_text-area{
    	    	width:100%;
    	    }
   	    }
   	    
		&__label{
		    margin-bottom: 0;
    		color: $brand-color-2;
    		font-size: .875rem;
		}
		select {
			display: block;
			width: 100%;
			height: 2.1rem;
			padding: 0.375rem 0.75rem;
			background-color: $light;
		}
	}
	
	.form-field-validation-error{
	    width: 100%;
    	color: $brand-color-1;
    	margin-bottom: 0px;
	}
	
	.dynamic-number-field-content, .dynamic-text-field-content, .dynamic-text-area-field-content, .dynamic-single-select-content, .dynamic-multi-select-content, .dynamic-date-field-content, .dynamic-time-field-content, .dynamic-html-field, .dynamic-selection-grouped{
	   width: 100%;
	   display: table;
	   content: '';
	   
	}		

	 input[type="text"], input[type="number"] {
	   width: 100%;
	   padding: 0.5rem 1rem;
	   background: none;
	   font-size: 0.875rem;
	   vertical-align: middle;
	   line-height: normal;
	   background-color: $input-bgcolor;
	   border: 1px solid;
	   border-color: $input-border-color;
	   max-height: 2.125rem;
	 }	
	
	@include hide-number-spinner;
	
	.dynamic-grouped-form{
	    width: 100%;
		display: table;
		content:'';
	    border: 2px solid $brand-color-3;
		padding: 0.5rem;
		min-height: 6.313rem;
	}
	
	.group-form{
		width: 100%;
		display: table;
		content:'';
		&__title{
		    background-color: $brand-color-3;
		    color: $brand-color-2;
		    font-size: 1.25rem;
	        padding-left: 0.5rem;				       
		    width: 100%;		    
    		font-weight: 500;	   
		}
	}
	
	.dynamic-form-actions{
	    display: table;
	    content: '';
	    margin-left: 0.5rem;
	    margin-top: 0.5rem;	
		
		&__add {
		    width: 100%;
	        float: left;
		    &_plus {
		        background: transparent;
		    }
	
		    &_plus:hover, &_plus:focus {
				color: $brand-color-2;
		    	font-size: 1rem;
		    }	    
		    	
		}
		
		&__remove {
		    width: 100%;
	        float: right;
	        margin-top: -0.5rem;
		    &_trash {
		        background: transparent;
		    	padding: 0.25rem 0.25rem 0 0.25rem;
	  			font-size: 0.9rem;
		    }
		    &_trash:hover,&_trash:focus  {
		    	color: $brand-color-1;
				font-size: 1.25rem;
		    }	
		}
	}	
	
	
	.action__add_plus, .action__remove_trash{
   		background-color: $light;
   		font-weight: 700;
   		text-decoration: underline; 
   		background: transparent;
   		padding-left: 0;	
	}
	
	.two_col_layout {
		width: 50%;
		float: left;
		min-height: 4.875rem;
	}
	.one_col_layout {
		width: 100%;
		display: table;
		content:'';
		padding-bottom: 0.5rem;		
	}
	.three_col_layout {
		width: 33%;
		float: left;
		min-height: 4.875rem;
	}
	.four_col_layout {
		width: 25%;
		float: left;
		min-height: 5.5625rem;
	}
	
	.dynamic-number-field, .dynamic-text-field,.dynamic-text-area-field, .dynamic-single-select, .dynamic-multi-select, .dynamic-date-field, .dynamic-date-range-field, .dynamic-time-field, .dynamic-html-field, .dynamic-selection-grouped {
		&__value-with-add-more{
			width:85%;
			float: left;
			padding-right: 0;
		}
		
		&__add-more{
			width:15%;
			float: left;
		}		
	}
	
	.group-form__action{
		.dynamic-form-actions{
			width:100%;
			margin-left: 0;
			.action__add, .action__remove{
				width: 50%;
			}
		}
	}
	.date-picker, .date-range-picker{
		&__from-date, &__to-date{
		    width: 50%;
    		float: left;		
		}
		&__from-date{
			padding-left: 0;
			padding-right: 0.9375rem;					
		}
		&__to-date{
			padding-left: 0.9375rem;
			padding-right: 0;
		}		
	}
	.dynamic-date-field{
		&__add-more{
			padding-top: 1.25rem;
		}
	}	
	
	.dynamic-row{
		display:table; content:''; width: 100%;
		min-height: 1px;
	}
	
	.dynamic-date-range-field {
		&__date-range_from-date, &__date-range_to-date{
		    width: 50%;
	   		float: left;	
		}
		label{
			color: $brand-color-2;
		}		
	}
}

@mixin  dynamic-form{ 
	@include dynamic-form_private; 
}