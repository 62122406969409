@import "../../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/selected-client-info/selected-client-info";

.selected-client-info {
	display: flex;
	&__cpg-balance {
		margin-right: 0.25rem;
	}
	
	&__client {
		&_name{
			padding: .2rem 0;	
		}
	}
}

